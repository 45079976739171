// default styles
html {
  font-size: 14px;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  color: white;
  background-color: #000;
}

#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: 'proximanova', sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.6px;
}

body,
button,
input,
optgroup,
select,
textarea,
pre {
  font-family: 'proximanova', 'noto_sanslight', 'sans-serif';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.6px;
}

:root {
  --finding-white-color: rgb(255, 255, 255);
  --finding-focused-contour-color: rgb(255, 194, 17);
  --button-background-color: rgba(255, 255, 255, 0.2);
  --button-label-color: rgba(255, 255, 255, 0.7);
  --button-background-color-hover: rgba(255, 255, 255, 0.35);
  --button-label-color-hover: rgba(255, 255, 255, 0.9);
  --button-background-color-selected: #00a4c8;
  --button-label-color-selected: #ffffff;
  --button-background-color-disabled: rgba(255, 255, 255, 0.12);
  --button-label-color-disabled: rgba(255, 255, 255, 0.2);

  --panel-background-color: #242e3e;
  --panel-header-background-color: rgba(255, 255, 255, 0.03);
  --panel-icon-color: #8694b1;
  --panel-icon-color-hover: #c5d8f8;
  --panel-title-color: #ffffff;

  --slider-rail-color: rgba(255, 255, 255, 0.4);
  --slider-thumb-color: #ffffff;
  --slider-track-color: rgba(255, 255, 255, 0.6);
  --slider-value-label-color: rgba(0, 0, 0, 0.4);

  --tooltip-background-color: rgba(0, 0, 0, 0.7);
  --tooltip-label-color: #ffffff;

  --snackbar-background-color: rgba(0, 0, 0, 0.7);
  --snackbar-label-color: #ffffff;

  --switch-color: #eeeeee;
  --switch-track-color: rgba(255, 255, 255, 0.2);
  --switch-color-checked: #00a5c8;
  --switch-track-color-checked: rgba(255, 255, 255, 0.2);

  --ctl-brand-color: var(--button-background-color-selected);
  --ctl-brand-color-rgb: 0, 164, 200;
  --ctl-brand-color-light: #00c9f5;
  --ctl-background-color: #161d2c;
  --ctl-background-color-dark: #030a18;
  --ctl-background-color-light: var(--panel-background-color);
  --ctl-background-color-lighter: rgba(255, 255, 255, 0.1);
  --ctl-background-color-lightest: rgba(255, 255, 255, 0.2);
  --ctl-border-color-light: #434959;
  --ctl-color: #8993ae;
  --ctl-color-active: #fff;
  --ctl-border: 1px solid var(--ctl-background-color-lighter);
  --ctl-header-height: 50px;
  --ctl-aside-width: 280px;
  --ctl-footer-height: 60px;
  --ctl-border-radius: 4px;
  --ctl-transition-timing: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  --contour-viewer-color: var(--finding-white-color);
  --contour-viewer-focused-color: var(--finding-focused-contour-color);
  --contour-viewer-stroke-width: 3px;
  --contour-viewer-focused-stroke-width: 6px;
  --contour-viewer-fill-color: rgba(0, 0, 0, 0.2);
  --contour-viewer-focused-fill-color: rgba(0, 0, 0, 0.4);

  --contour-drawer-color: rgb(255, 224, 0);
  --contour-drawer-stroke-width: 4px;
  --contour-drawer-fill-color: rgba(255, 224, 0, 0.2);
  --viewonly-stroke-color: #6a6e91;
  --pin-color: var(--finding-white-color);
  --pin-focused-color: var(--finding-focused-contour-color);
}
